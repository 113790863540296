import React from "react"

import {
  Heading,
  Head,
  Paragraph,
  Spacer,
  Container,
  SecondarySection,
  Row,
  Column,
  Button,
} from "../../components/components"

const changeCookieSettingsHandler = () => {
  ;(window as any).cookiehub.openSettings()
}

const Privacy = () => {
  return (
    <>
      <Head pagePath="/datenschutz" />
      <SecondarySection>
        <Container>
          <Row style={{ marginTop: "3rem" }}>
            <Column style={{ paddingTop: "40px" }}>
              <Button
                onClick={changeCookieSettingsHandler}
                style={{ marginBottom: "3rem" }}
              >
                Cookie Einstellungen ändern
              </Button>

              <Heading tag="h1">Datenschutzerklärung</Heading>
              <Paragraph>
                Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen.
                Datenschutz hat einen besonders hohen Stellenwert für die
                Geschäftsleitung der Incoqnito GmbH. Eine Nutzung der
                Internetseiten der Incoqnito GmbH ist grundsätzlich ohne jede
                Angabe personenbezogener Daten möglich. Sofern eine betroffene
                Person besondere Services unseres Unternehmens über unsere
                Internetseite in Anspruch nehmen möchte, könnte jedoch eine
                Verarbeitung personenbezogener Daten erforderlich werden. Ist
                die Verarbeitung personenbezogener Daten erforderlich und
                besteht für eine solche Verarbeitung keine gesetzliche
                Grundlage, holen wir generell eine Einwilligung der betroffenen
                Person ein.
              </Paragraph>
              <Paragraph>
                Die Verarbeitung personenbezogener Daten, beispielsweise des
                Namens, der Anschrift, E-Mail-Adresse oder Telefonnummer einer
                betroffenen Person, erfolgt stets im Einklang mit der
                Datenschutz-Grundverordnung und in Übereinstimmung mit den für
                die Incoqnito GmbH geltenden landesspezifischen
                Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung
                möchte unser Unternehmen die Öffentlichkeit über Art, Umfang und
                Zweck der von uns erhobenen, genutzten und verarbeiteten
                personenbezogenen Daten informieren. Ferner werden betroffene
                Personen mittels dieser Datenschutzerklärung über die ihnen
                zustehenden Rechte aufgeklärt.
              </Paragraph>
              <Paragraph>
                Die Incoqnito GmbH hat als für die Verarbeitung Verantwortlicher
                zahlreiche technische und organisatorische Maßnahmen umgesetzt,
                um einen möglichst lückenlosen Schutz der über diese
                Internetseite verarbeiteten personenbezogenen Daten
                sicherzustellen. Dennoch können Internetbasierte
                Datenübertragungen grundsätzlich Sicherheitslücken aufweisen,
                sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus
                diesem Grund steht es jeder betroffenen Person frei,
                personenbezogene Daten auch auf alternativen Wegen,
                beispielsweise telefonisch, an uns zu übermitteln.
              </Paragraph>
              <Spacer gutter={40} />
              <Heading tag="h1">Begriffsbestimmungen</Heading>
              <Paragraph>
                Die Datenschutzerklärung der Incoqnito GmbH beruht auf den
                Begrifflichkeiten, die durch den Europäischen Richtlinien- und
                Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung
                (DS-GVO) verwendet wurden. Unsere Datenschutzerklärung soll
                sowohl für die Öffentlichkeit als auch für unsere Kunden und
                Geschäftspartner einfach lesbar und verständlich sein. Um dies
                zu gewährleisten, möchten wir vorab die verwendeten
                Begrifflichkeiten erläutern. Wir verwenden in dieser
                Datenschutzerklärung unter anderem die folgenden Begriffe:
              </Paragraph>
              <Heading tag="h1">a) personenbezogene Daten</Heading>
              <Paragraph>
                Personenbezogene Daten sind alle Informationen, die sich auf
                eine identifizierte oder identifizierbare natürliche Person (im
                Folgenden „betroffene Person“) beziehen. Als identifizierbar
                wird eine natürliche Person angesehen, die direkt oder indirekt,
                insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
                zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
                oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck
                der physischen, physiologischen, genetischen, psychischen,
                wirtschaftlichen, kulturellen oder sozialen Identität dieser
                natürlichen Person sind, identifiziert werden kann.
              </Paragraph>
              <Heading tag="h1">b) betroffene Person</Heading>
              <Paragraph>
                Betroffene Person ist jede identifizierte oder identifizierbare
                natürliche Person, deren personenbezogene Daten von dem für die
                Verarbeitung Verantwortlichen verarbeitet werden.
              </Paragraph>
              <Heading tag="h1">c) Verarbeitung</Heading>
              <Paragraph>
                Verarbeitung ist jeder mit oder ohne Hilfe automatisierter
                Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im
                Zusammenhang mit personenbezogenen Daten wie das Erheben, das
                Erfassen, die Organisation, das Ordnen, die Speicherung, die
                Anpassung oder Veränderung, das Auslesen, das Abfragen, die
                Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder
                eine andere Form der Bereitstellung, den Abgleich oder die
                Verknüpfung, die Einschränkung, das Löschen oder die
                Vernichtung.
              </Paragraph>
              <Heading tag="h1">d) Einschränkung der Verarbeitung</Heading>
              <Paragraph>
                Einschränkung der Verarbeitung ist die Markierung gespeicherter
                personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung
                einzuschränken.
              </Paragraph>

              <Heading tag="h1">e) Profiling</Heading>
              <Paragraph>
                Profiling ist jede Art der automatisierten Verarbeitung
                personenbezogener Daten, die darin besteht, dass diese
                personenbezogenen Daten verwendet werden, um bestimmte
                persönliche Aspekte, die sich auf eine natürliche Person
                beziehen, zu bewerten, insbesondere, um Aspekte bezüglich
                Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, persönlicher
                Vorlieben, Interessen, Zuverlässigkeit, Verhalten,
                Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu
                analysieren oder vorherzusagen.
              </Paragraph>
              <Heading tag="h1">f) Pseudonymisierung</Heading>
              <Paragraph>
                Pseudonymisierung ist die Verarbeitung personenbezogener Daten
                in einer Weise, auf welche die personenbezogenen Daten ohne
                Hinzuziehung zusätzlicher Informationen nicht mehr einer
                spezifischen betroffenen Person zugeordnet werden können, sofern
                diese zusätzlichen Informationen gesondert aufbewahrt werden und
                technischen und organisatorischen Maßnahmen unterliegen, die
                gewährleisten, dass die personenbezogenen Daten nicht einer
                identifizierten oder identifizierbaren natürlichen Person
                zugewiesen werden.
              </Paragraph>
              <Heading tag="h1">
                g) Verantwortlicher oder für die Verarbeitung Verantwortlicher
              </Heading>
              <Paragraph>
                Verantwortlicher oder für die Verarbeitung Verantwortlicher ist
                die natürliche oder juristische Person, Behörde, Einrichtung
                oder andere Stelle, die allein oder gemeinsam mit anderen über
                die Zwecke und Mittel der Verarbeitung von personenbezogenen
                Daten entscheidet. Sind die Zwecke und Mittel dieser
                Verarbeitung durch das Unionsrecht oder das Recht der
                Mitgliedstaaten vorgegeben, so kann der Verantwortliche
                beziehungsweise können die bestimmten Kriterien seiner Benennung
                nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
                vorgesehen werden.
              </Paragraph>
              <Heading tag="h1">h) Auftragsverarbeiter</Heading>
              <Paragraph>
                Auftragsverarbeiter ist eine natürliche oder juristische Person,
                Behörde, Einrichtung oder andere Stelle, die personenbezogene
                Daten im Auftrag des Verantwortlichen verarbeitet.
              </Paragraph>
              <Heading tag="h1">i) Empfänger</Heading>
              <Paragraph>
                Empfänger ist eine natürliche oder juristische Person, Behörde,
                Einrichtung oder andere Stelle, der personenbezogene Daten
                offengelegt werden, unabhängig davon, ob es sich bei ihr um
                einen Dritten handelt oder nicht. Behörden, die im Rahmen eines
                bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem
                Recht der Mitgliedstaaten möglicherweise personenbezogene Daten
                erhalten, gelten jedoch nicht als Empfänger.
              </Paragraph>
              <Heading tag="h1">j) Dritter</Heading>
              <Paragraph>
                Dritter ist eine natürliche oder juristische Person, Behörde,
                Einrichtung oder andere Stelle außer der betroffenen Person, dem
                Verantwortlichen, dem Auftragsverarbeiter und den Personen, die
                unter der unmittelbaren Verantwortung des Verantwortlichen oder
                des Auftragsverarbeiters befugt sind, die personenbezogenen
                Daten zu verarbeiten.
              </Paragraph>
              <Heading tag="h1">k) Einwilligung</Heading>
              <Paragraph>
                Einwilligung ist jede von der betroffenen Person freiwillig für
                den bestimmten Fall in informierter Weise und unmissverständlich
                abgegebene Willensbekundung in Form einer Erklärung oder einer
                sonstigen eindeutigen bestätigenden Handlung, mit der die
                betroffene Person zu verstehen gibt, dass sie mit der
                Verarbeitung der sie betreffenden personenbezogenen Daten
                einverstanden ist.
              </Paragraph>
            </Column>
          </Row>
        </Container>
      </SecondarySection>
    </>
  )
}

export default Privacy
